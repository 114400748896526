.input-wrapper {
	margin: 1rem 0;
	width: 100%;
}

.error-message {
	color: red;
}

.clock-ticking {
	color: red;
}

.action-btn > button {
	width: 2rem;
}

.action-column {
	width: 8rem;
}

.time-column {
	width: 8rem;
}

.toolbar-right {
	display: flex;
	flex-direction: row;
	justify-content: right;
	width: 50%;
}

.toolbar-left {
	display: flex;
	flex-direction: row;
	justify-content: left;
	width: 50%;
}

#toolbar {
	display: flex;
}

#toolbar > div > button {
	margin: 0 2px;
}

.p-datatable-emptymessage td {
	text-align: center;
}

.activity-column {
	width: 10rem;
}

.container {
	margin: 1rem;
}

.notes {
	white-space: pre-line;
}

.p-datatable-table > thead > tr:nth-child(2) {
	background-color: red;
}

thead input, thead select, thead .p-dropdown-label.p-inputtext {
	padding: 0.5rem;
}

header {
	background-color: black;
	padding: 0.5rem;
	color: #eee;
	font-weight: bold;
}

@media screen and (max-width: 920px) {
	.container {
		margin: 0.2rem;
	}

	.p-datatable-table, .p-paginator {
		font-size: 0.8rem;
	}

	.action-btn > button {
		width: 2rem;
		height: 2rem;
	}

	.action-column {
		width: 2rem;
		padding: 0.2rem;
	}

	.time-column {
		width: 6rem;
	}

	.p-datatable-table > thead > tr > th:last-child {
		display: none;
	}

	.p-datatable-table > tbody > tr > td:last-child {
		display: none;
	}

	.p-paginator-bottom.p-paginator.p-component .p-dropdown {
		display: none;
	}

	.p-paginator-bottom.p-paginator.p-component {
		padding: 1rem 0;
	}
}
